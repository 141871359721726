import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { refreshTokenKey } from "../component/token";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const nav = useNavigate();

  const submit = () => {
    setError("");
    fetch("/api/user/login", {
      method: "POST",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          const refreshToken = data.data.refresh_token;
          localStorage.setItem(refreshTokenKey, refreshToken);
          nav("/");
        } else {
          setError(data.data.message);
        }
      });
  };

  return (
    <Box
      sx={{ pt: 20 }}
      style={{ backgroundImage: "url('/bg.jpg')" }}
      className="login-bg"
    >
      <Container
        sx={{
          width: 500,
          p: 5,
          borderRadius: 2,
          bgcolor: "rgba(255, 255, 255, 0.85)",
        }}
      >
        <h1>Login to DCP Administration</h1>
        {error && (
          <Alert severity="warning" onClose={() => setError("")}>
            {error}
          </Alert>
        )}
        <FormControl fullWidth>
          <FormLabel>Email:</FormLabel>
          <TextField onChange={(e) => setEmail(e.target.value)} size="small" />
        </FormControl>
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <FormLabel>Password:</FormLabel>
          <TextField
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            size="small"
          />
        </FormControl>

        <br />
        <br />
        <div>
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
          <Button>Reset</Button>
        </div>
      </Container>
    </Box>
  );
};
