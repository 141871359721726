import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  TextField,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../component/useApi";

export const Create = (props: {
  shown: boolean;
  onHide: () => void;
  onSuccess: () => void;
}) => {
  const [projectName, setProjectName] = useState("");
  const [host, setHost] = useState("");
  const [website, setWebsite] = useState(false);

  const api = useApi();

  const submit = () => {
    api
      .post("/api/project/create", {
        name: projectName,
        host: host,
        website: website,
      })
      .then((data) => {
        if (data.error === false) {
          props.onSuccess();
        } else {
          window.alert(data.data.message);
        }
      });
  };

  return (
    <div>
      <Dialog
        open={props.shown}
        fullWidth={true}
        onClose={() => props.onHide()}
      >
        <DialogTitle>Creat project</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true}>
            <label>Project name:</label>
            <TextField
              size="small"
              required
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <label>Host:</label>
            <TextField
              size="small"
              placeholder="We will create one if it's empty so it can be changed later."
              onChange={(e) => setHost(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <label>
              Has website:
              <Checkbox onChange={(e) => setWebsite(e.target.checked)} />
            </label>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
          <Button onClick={() => props.onHide()}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
