import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  createBrowserRouter,
  redirect,
  Router,
  RouterProvider,
} from "react-router-dom";
import { Login } from "./page/Login";
import { Layout } from "./Layout";
import { List } from "./page/List";
import { List as UserList } from "./page/user/List";
import { useAccessToken } from "./component/token";

function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      loader: async () => {
        return redirect("/project/list");
      },
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "project/list",
          element: <List />,
        },
        {
          path: "user/list",
          element: <UserList />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
