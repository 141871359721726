import { useEffect, useState } from "react";

export let accessToken = "";

export const refreshTokenKey = "refresh-token";

export const useAccessToken = () => {
  const [aToken, setAtoken] = useState("");

  const renewToken = () => {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    if (!refreshToken) {
      console.warn("No refresh token in storage. Go to login");
      return;
    }
    fetch("/api/user/renew", {
      method: "POST",
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error === false) {
          const token = data.data;
          setAtoken(token);
          accessToken = token;
        } else {
          //remove
          localStorage.removeItem(refreshTokenKey);
        }
      });
  };

  useEffect(() => {
    if (!aToken) {
      renewToken();
    }
  }, []);

  return aToken;
};
