import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../../component/useApi";

export const List = () => {
  const [list, setList] = useState([]);

  const api = useApi();

  const fetchList = () => {
    api.get("/api/user/list").then((data) => {
      setList(data.data);
    });
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div>
      <Container>
        <h2>Users</h2>
        <div style={{ textAlign: "right" }}>
          <Button variant="contained">Add user</Button>
        </div>
        <Table>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {list.map((item: any) => (
              <TableRow>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  {item.firstname} {item.lastname}
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.role === 1 && <span>Admin</span>}</TableCell>
                <TableCell>
                  <Button>Change password</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </div>
  );
};
