import { accessToken } from "./token";

export const useApi = () => {
  const getTokenHeader = () => {
    return "Bearer " + accessToken;
  };

  const post = async (url: string, body: object) => {
    const res = await fetch(url, {
      method: "POST",
      headers: { Authorization: getTokenHeader() },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    return data;
  };

  const get = async (url: string) => {
    const res = await fetch(url, {
      headers: { Authorization: getTokenHeader() },
    });
    const data = await res.json();
    return data;
  };

  return { get, post };
};
