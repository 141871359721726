import {
  HomeOutlined,
  LaunchOutlined,
  ListAltOutlined,
  LogoutOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Container,
  Alert,
  Grid,
  Grid2,
} from "@mui/material";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { refreshTokenKey, useAccessToken } from "./component/token";
import { useApi } from "./component/useApi";

const links = [
  { link: "/project/list", icon: <ListAltOutlined />, text: "Projects" },
  { link: "/user/list", icon: <Person2Outlined />, text: "Users" },
];

export const Layout = () => {
  const accessToken = useAccessToken();

  const api = useApi();

  const nav = useNavigate();
  const logout = () => {
    api
      .post("/api/user/logout", {
        token: localStorage.getItem(refreshTokenKey),
      })
      .then((data) => {
        if (data.data === true) {
          localStorage.removeItem(refreshTokenKey);
          nav("/login");
        }
      });
  };

  if (!accessToken) {
    return (
      <Container>
        <br /> <br />
        <Alert severity="warning">
          Need authorization. Click to <a href="/login">login</a>
        </Alert>
      </Container>
    );
  }
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer
          sx={{
            width: 250,
            flex: "initial",
            "& .MuiDrawer-paper": {
              width: 250,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box sx={{ p: 2 }}>
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <img src="/logo.png" height={40} />
              <span style={{ marginLeft: 10 }}>DCP Administration</span>
            </Link>
          </Box>
          <List component="nav">
            {links.map((item) => (
              <NavLink to={item.link}>
                {({ isActive }) => (
                  <ListItemButton selected={isActive}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                )}
              </NavLink>
            ))}
          </List>
        </Drawer>
        <Box sx={{ flex: "auto" }}>
          <div>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  if (window.confirm("Are you sure to logout?")) {
                    logout();
                  }
                }}
              >
                {" "}
                <LogoutOutlined />
                Logout
              </Button>
            </div>
          </div>
          <div>
            <Outlet />
          </div>
        </Box>
      </Box>
    </div>
  );
};
