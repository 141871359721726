import {
  ArrowDropDownOutlined,
  CheckBox,
  CheckBoxOutlined,
  CheckCircleOutline,
  DeleteOutline,
  DoDisturbAlt,
  LaunchOutlined,
  MoreHorizOutlined,
  RestartAltOutlined,
} from "@mui/icons-material";
import {
  Button,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Create } from "./Create";
import { useApi } from "../component/useApi";

export const List = () => {
  const [list, setList] = useState<
    Array<{
      id: number;
      name: string;
      user_id: number;
      identifier: string;
      project_id: string;
      host: string;
      status: number;
      website: boolean;
      website_host: string;
    }>
  >([]);

  const api = useApi();

  const fetchList = () => {
    api.get("/api/project/list").then((data) => {
      setList(data.data);
    });
  };

  const deleteProject = (id: number) => {
    api.get("/api/project/delete/" + id).then((data) => {
      if (data.error === false) {
        fetchList();
      }
    });
  };

  const [showCreate, setShowCreate] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [menuID, setMenuID] = useState(0);

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <Container>
      <h2>Project list</h2>
      <div style={{ textAlign: "right" }}>
        <Button variant="contained" onClick={() => setShowCreate(true)}>
          Create project
        </Button>
      </div>

      <Create
        shown={showCreate}
        onHide={() => setShowCreate(false)}
        key={showCreate + ""}
        onSuccess={() => {
          setShowCreate(false);
          fetchList();
        }}
      />
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>DCP Host</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Public ID</TableCell>
              <TableCell>Internal ID</TableCell>
              <TableCell>Has website</TableCell>
              <TableCell>Website</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow hover>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell
                  title={item.host}
                  sx={{ width: 80, overflowX: "hidden" }}
                >
                  {item.host}
                </TableCell>
                <TableCell>
                  {item.status === 0 && (
                    <span style={{ color: "gray" }}>Disabled</span>
                  )}
                  {item.status === 1 && (
                    <span style={{ color: "green" }}>Active</span>
                  )}
                  {item.status === 2 && (
                    <span style={{ color: "red" }}>Internal error</span>
                  )}
                </TableCell>
                <TableCell>{item.project_id}</TableCell>
                <TableCell>{item.identifier}</TableCell>
                <TableCell>
                  {item.website ? <CheckCircleOutline /> : <></>}
                </TableCell>
                <TableCell>
                  <a href={"https://" + item.website_host}>
                    {item.website_host}
                  </a>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={(e) => {
                      setMenuID(item.id);
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    Actions
                    <ArrowDropDownOutlined />
                  </Button>
                  <Menu
                    open={menuAnchor && menuID === item.id ? true : false}
                    anchorEl={menuAnchor}
                    onClose={() => {
                      setMenuID(0);
                      setMenuAnchor(null);
                    }}
                  >
                    <MenuItem disabled>
                      <ListItemText>Website</ListItemText>
                    </MenuItem>
                    <a
                      href={"https://" + item.website_host}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={() => {}}>
                        <ListItemText>Visit website</ListItemText>
                      </MenuItem>
                    </a>
                    <a
                      href={"https://" + item.website_host + "/dm-admin"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={() => {}}>
                        <ListItemText>Content admin &nbsp;</ListItemText>
                        <ListItemIcon>
                          <LaunchOutlined fontSize="small" />
                        </ListItemIcon>
                      </MenuItem>
                    </a>
                    <MenuItem disabled>
                      <ListItemText>Tenant</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {}}>
                      <ListItemIcon>
                        <DoDisturbAlt />{" "}
                      </ListItemIcon>
                      <ListItemText>Disable</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => {}}>
                      <ListItemIcon>
                        <RestartAltOutlined />{" "}
                      </ListItemIcon>
                      <ListItemText>Reset data</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure to delete " +
                              item.name +
                              ", " +
                              item.id +
                              "?"
                          )
                        ) {
                          deleteProject(item.id);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutline />{" "}
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
